import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import {AMBIT} from './../../../routing/ambits/routes';
import {TECHNOLOGY} from './../../../routing/technologies/routes';
// import {PROJECT} from './../../../routing/projects/routes';

import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingLogosGrid from './../../_bridges/RoutingLogosGrid/RoutingLogosGrid';

import {ICON} from './../../atoms/Iconography/Iconography';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import RelatedAreas from './../../components/RelatedAreas/RelatedAreas';
import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';

import GenericHero from './../../compositions/GenericHero/GenericHero';
import RelatedServices from './../../compositions/RelatedServices/RelatedServices';
// import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import Grid from './../../layouts/Grid/Grid';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './Ambit.scss';

const icon = ICON.FORWARD_ARROW;

const Ambit = ({canonical, content: area, intl}) => (
  <div className="ambit">
    <GenericHero
      ambitHero={area.ambitHero}
      color={area.gradient.type}
      image={area.image}
      title={area.title}
      type={intl.formatMessage({id: 'ambit.category'})}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {area.relatedContact.items.length > 0 &&
            area.relatedContact.items.map((contact, index) => (
              <RoutingExpertModule
                expertPageSlug={contact.slug}
                formId={area.formId.id}
                formInfo={area.form.find(form => form.type === 'expert')}
		hidden={true}
                imgAlt={contact.title}
                imgSrc={contact.image.src.small}
		isInspiringBlogAuthor={false}
                key={index}
                name={contact.title}
                position={contact.position}
                templateTitle={area.title}
		/>
            ))}

          {area.catalog.items.map((catalog, index) => (
            <CatalogueDownload
              catalogueInfo={catalog}
              formId={area.formId.catalogId}
              formInfo={area.form.find(form => form.type === 'download')}
              key={index}
            />
          ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <div className="ambit__top">
          <Content className="ambit__main-excerpt" raw variant={VARIANT.EXCERPT}>
            {area.detailedExcerpt}
          </Content>
          {area.content && <CmsContent className="ambit__main-content" content={area.content} />}
          {area.relatedPartnerGroups &&
            area.relatedPartnerGroups.map((partnerGroup, index) => (
              <RoutingLogosGrid
                breakpoints={{S: 2, M: 3, L: 6}}
                key={index}
                logos={partnerGroup.items}
                maxLogos={6}
                title={partnerGroup.title}
              />
            ))}
        </div>
      }
      socialText={area.title}
    />
    <MainLayout
      canonical={canonical}
      mainContent={
        <React.Fragment>
          <div className="ambit__technologies">
            <Typography className="ambit__technologies-title" tag="h2" variant={VARIANT.HEADING}>
              {area.technologies.title}
            </Typography>
            <Grid className="ambit__technologies-cards" columnsPerBreakpoint={{S: 1, M: 2, XL: 3}}>
              {area.technologies.items.map((technology, index) => (
                <RoutingGenericPreview key={index} preview={technology.fields.relatedPage} to={TECHNOLOGY} />
              ))}
            </Grid>
          </div>
        </React.Fragment>
      }
      print={false}
      showSocial={false}
    />
    {area.relatedBusinessOpportunity.items.length > 0 && (
      <RelatedServices cards={area.relatedBusinessOpportunity.items} title={area.relatedBusinessOpportunity.title} />
    )}

    {/* {area.relatedProject.items.length > 0 && (
      <RelatedContentCards
        slidesPerBreakpoint={{S: 1, L: 2, XXL: 3, XXXXL: 4}}
        title={intl.formatMessage({id: 'project.category'})}
      >
        {area.relatedProject.items.map((project, index) => (
          <RoutingGenericPreview
            key={index}
            label={intl.formatMessage({id: 'project.category'})}
            preview={project}
            to={PROJECT}
          />
        ))}
      </RelatedContentCards>
    )} */}
    <RelatedAreas
      areas={area.ambits.items}
      icon={icon}
      title={area.ambits.title}
      titleTag="h4"
      to={AMBIT}
      withBackground
    />
  </div>
);

export default compose(injectIntl, React.memo)(Ambit);
